<template>
  <div class="inner-section">
    <card></card>
    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('database_dump.downloadable') }} {{ $t('database_dump.database')}} {{ $t('database_dump.list') }}</h4>
      </template>
      <template v-slot:body>
        <b-overlay :show="loadingState">
          <b-row>
            <b-col md="12" class="table-responsive">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(attachment)="data">
                    <div class="text-center">
                      <a class="badge badge-primary" @click="prepareMysqlDump(data)"
                      >
                        <i class="ri-cloud-fill"></i>
                        {{ $t('globalTrans.attachment') }}
                      </a>
                    </div>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
               </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
  </div>
</template>
<style scoped>
table#table-transition-example .flip-list-move {
  transition: transform 1s;
}
</style>
<script>
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { databaseBackupList, databaseBackupUpdate } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import i18n from '@/i18n'
import Card from '../../../../../components/Card.vue'

const defaultColumn = [
  { label_en: i18n.messages.en.globalTrans.sl_no, label_bn: i18n.messages.bn.globalTrans.sl_no, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
  { label_en: i18n.messages.en.database_dump.databaseName, label_bn: i18n.messages.bn.database_dump.databaseName, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '70%' } },
  { label_en: i18n.messages.en.database_dump.attachment, label_bn: i18n.messages.bn.database_dump.attachment, class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '22%' } }
]
export default {
  mixins: [ModalBaseMasterList],
  components: {
    Card
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl,
      transProps: {
        name: 'flip-list'
      },
      sortBy: '',
      search: {
        status: 3,
        limit: 20
      },
      sortDesc: true,
      sortDirection: 'desc',
      labelData: [],
      downloadUrl: '',
      fileName: '',
      fileFullPathDownloadUrl: ''
    }
  },
  created () {
    this.labelData = this.labelList
    this.loadData()
  },
  watch: {
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    labelList: function () {
      const dataList = defaultColumn
      return dataList.map(item => {
        if (this.$i18n.locale === 'bn') {
          const data = { value: item.label_en, text: item.label_bn }
          return Object.assign({}, item, data)
        } else {
          const data = { value: item.label_en, text: item.label_en }
          return Object.assign({}, item, data)
        }
      })
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        const labelData = {}
        labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
        return Object.assign(item, labelData)
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'database_name' },
          { key: 'attachment' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'database_name' },
          { key: 'attachment' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    pageOptions () {
      return this.$store.state.commonObj.pageOptions
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(commonServiceBaseUrl, databaseBackupList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          return Object.assign({}, item, { serial: index })
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    async prepareMysqlDump (data) {
      const result = await RestApi.putData(commonServiceBaseUrl, `${databaseBackupUpdate}/${data.item.id}`, { db_id: data.item.id })
      if (result.status === true) {
        this.downloadUrl = result.url
        this.fileName = result.fileName
        this.fileFullPathDownloadUrl = commonServiceBaseUrl + this.downloadUrl
        // const url = window.URL.createObjectURL(new Blob([commonServiceBaseUrl + this.downloadUrl]))
        const link = document.createElement('a')
        link.href = this.fileFullPathDownloadUrl
        link.setAttribute('download', this.fileName)
        document.body.appendChild(link)
        link.click()
      } else {
        this.downloadUrl = ''
        this.fileName = ''
        this.fileFullPathDownloadUrl = ''
        this.$toast.success({
          title: 'error',
          message: this.$t('globalTrans.no_data_found'),
          color: '#D6E09B'
        })
      }
    }
  }
}
</script>
